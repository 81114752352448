import { api } from 'shared/api'

import { ChatApiSchemas } from './schemas'

export const chatApi = {
  markThreadAsUnread: api.account.createPost({
    url: '/im/markThreadAsUnread',
    schemas: ChatApiSchemas.markThreadAsUnread,
  }),
  getUserLocation: api.account.createGet({
    url: '/im/getUserLocation',
    schemas: ChatApiSchemas.getUserLocation,
  }),
  setPauseForever: api.account.createPost({
    url: '/im/setAutomationPauseForever',
    schemas: ChatApiSchemas.setAutomationPauseForever,
  }),
  setPause: api.account.createPost({
    url: '/im/setAutomationPause',
    schemas: ChatApiSchemas.setAutomationPause,
  }),
  clearPause: api.account.createPost({
    url: '/im/clearAutomationPause',
    schemas: ChatApiSchemas.clearPause,
  }),
}
