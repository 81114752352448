import { z } from 'zod'

import { ReducedUserSchema } from '../audience/schemas'

export const ChatApiSchemas = {
  markThreadAsUnread: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      user_id: z.string(),
    }),
    response: z.undefined(),
  },
  getUserLocation: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: z.object({
      coordinates: z.object({
        longitude: z.string(),
        latitude: z.string(),
      }),
    }),
  },
  setAutomationPauseForever: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      user_id: z.string(),
    }),
    response: z.object({
      state: z.boolean(),
      thread: ReducedUserSchema,
    }),
  },
  setAutomationPause: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      user_id: z.string(),
      delay: z.number(),
    }),
    response: z.object({
      state: z.boolean(),
      thread: ReducedUserSchema,
    }),
  },
  clearPause: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      user_id: z.string(),
    }),
    response: z.object({
      state: z.boolean(),
      thread: ReducedUserSchema,
    }),
  },
}
