import { l } from '@manychat/manyui'

import { updateThreadStatus } from 'apps/chat/actions/threadsActions'
import {
  ALL_TEAM_MEMBERS_ID,
  AssignEvent,
  LiveChatThread,
  UserThreadNewEvent,
  ChatThreadUser,
} from 'apps/chat/constants/LiveChatInterfaces'
import * as actionTypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { getAutomationPauseEndCaption } from 'apps/chat/helpers/pauseAutomationsHelpers'
import { ThreadStatus } from 'apps/chat/interfaces/statuses'
import { setThreadAssignedToMe, setThreadUnassigned } from 'apps/chat/redux/notificationsSlice'
import {
  getTeamMemberFilter,
  getThreads,
  getThreadStatusesByStatusFilterAndFolder,
} from 'apps/chat/selectors/threadSelectors'
import { ChatMessageType } from 'apps/chat/types/chatMessageTypeList'
import * as atypesAudience from 'common/audience/constants/AudienceReduxActionTypes'
import { alert } from 'common/core'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getUserId } from 'common/core/selectors/appSelectors'
import { handleError } from 'shared/api/lib/errors/handlers'
import { chatApi } from 'shared/api/requests/chat'
import { navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import { linkURL } from 'utils/url'

export const handleThreadAssignedEvent = (socketData?: AssignEvent): IThunkAction => {
  return (dispatch, getState) => {
    const isSuitableEvent =
      socketData?.type &&
      [ChatMessageType.ASSIGN, ChatMessageType.REASSIGN].includes(socketData.type)

    if (!socketData?.model?.to_user_id || !isSuitableEvent) return

    const state = getState()
    const currentUserId = getUserId(state)
    const assignedUserId = socketData?.model?.to_user_id

    if (currentUserId === assignedUserId) {
      dispatch(setThreadAssignedToMe(Object.assign({}, socketData)))
    }
  }
}

export const handleUserThreadNewEvent = (socketData?: UserThreadNewEvent): IThunkAction => {
  return (dispatch) => {
    if (socketData?.type !== ChatMessageType.USER_THREAD_NEW || !socketData) return

    if (socketData?.model?.before === 'closed') {
      dispatch(setThreadUnassigned(Object.assign({}, socketData)))
      dispatch({ type: actionTypes.THREADS_INCREASE_TOTAL_THREADS_COUNT })
    }
  }
}

export const handleUpdateSubscriberInfo = (socketData?: ChatThreadUser): IThunkAction => {
  return (dispatch) => {
    if (!socketData || !socketData?.user_id) return

    return dispatch({
      type: actionTypes.THREADS_UPDATE_SUBSCRIBER_INFO,
      threadId: socketData?.user_id,
      user: socketData,
    })
  }
}

export function getNextThreadId(
  threads: LiveChatThread[],
  currentThreadId: LiveChatThread['id'],
  initialThreadIndex: number,
) {
  const threadIndex = threads.findIndex((thread) => thread.id === currentThreadId)
  const wasThreadFilteredOut = threadIndex === -1
  let nextThreadIndex

  if (wasThreadFilteredOut) {
    nextThreadIndex = Math.min(initialThreadIndex, threads.length)
  } else if (initialThreadIndex === threads.length - 1) {
    nextThreadIndex = initialThreadIndex - 1
  } else {
    nextThreadIndex = initialThreadIndex + 1
  }

  const safeNextThreadIndex = Math.min(nextThreadIndex, threads.length)

  return threads[safeNextThreadIndex]?.id
}

export const updateThreadStatusAndSelectNextThread = (
  threadId: string,
  status: ThreadStatus,
): IThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()

    const teamMembersFilter = getTeamMemberFilter(state)
    const threads = getThreads(state) || []
    const threadsStatuses = getThreadStatusesByStatusFilterAndFolder(state)
    const initialThreadIndex = threads.findIndex((thread) => thread.id === threadId)

    const response = await dispatch(updateThreadStatus(threadId, status))
    if (!response) return

    let filteredThreads = threads

    if (!threadsStatuses.includes(status)) {
      filteredThreads = filteredThreads.filter((thread) => thread.id !== threadId)
    }

    if (teamMembersFilter && teamMembersFilter !== ALL_TEAM_MEMBERS_ID) {
      filteredThreads = filteredThreads.filter(
        (thread) => thread.assignment?.user_id === teamMembersFilter,
      )
    }

    filteredThreads = filteredThreads.filter((thread) =>
      threadsStatuses.includes(thread.lc_thread_status),
    )

    if (filteredThreads.length) {
      const nextThreadId = getNextThreadId(threads, threadId, initialThreadIndex)

      if (nextThreadId) {
        navigatePollyfill(linkURL(`/chat/${nextThreadId}`))
      }
    }
  }
}

export const setPauseForever = (threadId: string): IThunkAction => {
  return async (dispatch) => {
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_SET', { threadId, delay: 'forever' })
    analyticsService.sendEvent('LIVE_CHAT.PAUSE.CLICK')
    try {
      await chatApi.setPauseForever({
        body: {
          user_id: threadId,
        },
      })

      alert(l.translate('Automations paused forever'))

      const subscriber = {
        ts_automation_paused_until: null,
        ts_automation_paused_forever: true,
      }
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
        subscriber,
      })
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
        id: threadId,
        subscriber,
      })
    } catch (error) {
      handleError(error)
    }
  }
}

export const setPause = (threadId: string, delay: number): IThunkAction => {
  return async (dispatch) => {
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_SET', { threadId, delay })
    analyticsService.sendEvent('LIVE_CHAT.PAUSE.CLICK')
    try {
      const response = await chatApi.setPause({
        body: {
          user_id: threadId,
          delay,
        },
      })

      const timeStamp = response.data.thread.ts_automation_paused_until

      if (timeStamp) {
        alert(getAutomationPauseEndCaption(timeStamp))

        const subscriber = {
          ts_automation_paused_until: timeStamp,
          ts_automation_paused_forever: false,
        }
        dispatch({
          type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
          subscriber,
        })
        dispatch({
          type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
          id: threadId,
          subscriber,
        })
      }
    } catch (error) {
      handleError(error)
    }
  }
}

export const clearPause = (threadId: string): IThunkAction => {
  return async (dispatch) => {
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_CLEARED', { threadId })
    try {
      await chatApi.clearPause({
        body: {
          user_id: threadId,
        },
      })

      alert(l.translate('Automations resumed'))

      const subscriber = {
        ts_automation_paused_until: null,
        ts_automation_paused_forever: false,
      }
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
        subscriber,
      })
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
        id: threadId,
        subscriber,
      })
    } catch (error) {
      handleError(error)
    }
  }
}
